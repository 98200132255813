<template>
  <div style="width:100%;background:#f8f8f8;padding:40px">
    <el-select v-model="provinceCode" @change="getTree" placeholder="请选择" style="width:300px;margin:20px">
      <el-option label="全部" value=""></el-option>
      <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <el-button type="primary" @click="showAdd(1)" style="margin-bottom:10px">添加</el-button>
    <el-row>
      <el-col :span="12" style="background:#f8f8f8">
        <div class="h5-box">
          <el-carousel height="150px" @click.native="menuClick('home1')" style="background:#ccc">
            <el-carousel-item v-for="item in list1.childrenList" :key="item.id">
              <div style="height:100%;width:100%;background:#ccc">
                <img :src="item.icon" :alt="item.label" style="width:100%;height:100%"></img>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="!list1.childrenList || !list1.childrenList.length">
              <div>轮播图</div>
            </el-carousel-item>
          </el-carousel>
          <div class="product-box" @click="menuClick('home2')">
            <div class="product-samll-box" v-if="list2.childrenList && list2.childrenList.length">
              <div class="product" v-for="(item,i) in list2.childrenList" :key="i">
                <div class="img-box">
                  <p class="note" v-if="item.activity">{{item.activity}}</p>
                  <img class="img" :src="item.icon" alt="">
                </div>
                <p class="title">{{item.label}}</p>
                <p class="active">{{item.notes}}</p>
              </div>
            </div>
            <div v-else>菜单区</div>
          </div>
          <div class="active1 " @click="menuClick('home3')">
            <!-- <img class="img" v-if="list3.childrenList && list3.childrenList.length" :src="list3.childrenList[0].icon" alt=""> -->

            <div class="active3" v-if="list3.childrenList && list3.childrenList.length">
              <div class="active3-top">
                <img class="img" :src="list3.childrenList[0] && list3.childrenList[0].icon" alt="">
              </div>
              <div class="active3-bottom">
                <img class="img" :src="list3.childrenList[1] && list3.childrenList[1].icon" alt="">
                <img class="img" :src="list3.childrenList[2] && list3.childrenList[2].icon" alt="">
              </div>
            </div>
            <div v-else>活动一</div>
          </div>

          <div class="active1" @click="menuClick('home4')">
            <div class="active-small" v-if="list4.childrenList && list4.childrenList.length">
              <img v-for="item in list4.childrenList" :key="item.id" class="img" :src="item.icon" alt="">
            </div>
            <div v-else>活动二</div>
          </div>
          <div class="active1 active5" @click="menuClick('home5')">
            <div v-if="list5.childrenList && list5.childrenList.length">
              <img v-for="item in list5.childrenList" :key="item.id" class="img" :src="item.icon" alt="">
            </div>
            <div v-else>活动三</div>
          </div>
          <div @click="menuClick('home6')">
            <el-tabs v-if="list6.childrenList && list6.childrenList.length">
              <el-tab-pane :label="item.label" v-for="item in list6.childrenList" :key="item.id">
                <div class="coupons-box" v-for="(shop,index) in (item.code=='shop1'?shopList2:shopList)" :key="index">
                  <img class="coupons-img" src="https://gz.bcebos.com/v1/wlyn/bfb7503c-7cd6-48d4-a4ca-547c14223fa2.png" alt="" style="width:80px">
                  <div class="right-info">
                    <div class="title">{{shop.shopName}}</div>
                    <div class="mark"></div>
                    <div class="tips">
                      <div style="float:left;">营业时间： 24小时营业</div>
                      <div style="float:right;">{{shop.distance}}</div>
                    </div>
                  </div>
                  <div class="operation-bar">
                    <el-rate class="rate" v-model="rates" :size="14" readonly />
                    <p>5.0</p>
                    <div class="address" v-text="shop.shopAddress"></div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div v-else class="active1">门店</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="right-box" v-if="nowMenu.id">
          <el-row>
            <el-col :span="12">{{nowMenu.label}}</el-col>
            <el-col :span="12" style="text-align:right">
              <el-button type="primary" @click="showAdd(1)" style="margin-bottom:10px">添加</el-button>
              <el-button type="primary" @click="showAdd(2,nowMenu)" style="margin-bottom:10px">修改</el-button>
              <el-button type="success" @click="onSubmit" style="margin-bottom:10px">保存</el-button>
            </el-col>
          </el-row>
          <el-collapse accordion>
            <draggable v-model="nowMenu.childrenList" @start="drag=true" @end="drag=false" @update="datadragEnd" :options = "{animation:800}">
              <el-collapse-item v-for="item in nowMenu.childrenList" :key="item.id">
                <template slot="title">
                  <el-row style="width:100%">
                    <el-col :span="12">{{item.label}}</el-col>
                    <el-col :span="12" style="text-align:right">
                      <el-button size="mini" type="primary" plain @click.stop="showAdd(2,item)">修改</el-button>
                      <el-button size="mini" type="warning" plain @click.stop="setDisable(item.id,item.isDisable)" v-if="item.isDisable == 0">隐藏</el-button>
                      <el-button size="mini" type="success" plain @click.stop="setDisable(item.id,item.isDisable)" v-if="item.isDisable == 1">显示</el-button>
                      <el-button size="mini" type="danger" plain @click.stop="deleteOne(item.id)">删除</el-button>
                    </el-col>
                  </el-row>

                </template>
                <div class="content-text"><span>菜单名称</span> {{item.label}}</div>
                <div class="content-text">
                  <span>菜单图标</span>
                  <el-image :src="item.icon" style="width:80px;height:80px;vertical-align: middle"></el-image>
                </div>
                <div class="content-text"><span>菜单说明</span>{{item.notes}}</div>
                <div class="content-text" v-if="item.regionCode">
                  <span>菜单地区</span>
                  <span v-for="item in item.regionCode.split(',')" :key="item">{{item | province}}</span>
                </div>
                <div class="content-text"><span>菜单路径</span>{{item.path}}</div>
              </el-collapse-item>
            </draggable>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
    <AddOrUpdate v-if="show" :isAddDialog.sync="show" :parentId="nowMenu.id" :type="type" :row="row" @success="getTree" menuType='1'></AddOrUpdate>
  </div>
</template>
<script>
import { getMobileMenuPage, getAllMobileMenuList, isDisable, deleteMobileMenu, updateMobileMenu } from '@/api/appSetting'
import AddOrUpdate from '../menu/components/AddOrUpdate.vue'
import { provinceList } from '@/utils/utils.js'
import draggable from 'vuedraggable';
export default {
  name: 'setPage',
  components: {
    AddOrUpdate, draggable
  },
  data() {
    return {
      provinceCode: '',
      drag: false,
      rates: 5,
      shopList: [
        {          shopName: '郑州北区东风路加油站1',
          shopAddress: '东风路与丰乐路交叉口向东50米路北',
        },
        {          shopName: '郑州北区东风路加油站2',
          shopAddress: '东风路与丰乐路交叉口向东50米路北',
        },
      ],
      shopList2: [
        {          shopName: '深圳洗车店',
          shopAddress: '东风路与丰乐路交叉口向东50米路北',
        },
      ],
      list1: {},//轮播图
      list2: {},//菜单栏
      list3: {},//活动一
      list4: {},//活动二
      list5: {},//活动三
      list6: {},//门店
      Item: [],//
      Inverseanalysis: "",
      city: '',
      dataTree: [],
      nowMenu: {},
      type: '',
      row: {},
      show: false,
      provinceList: provinceList
    }
  },
  created() {
    console.log(draggable)
    this.getTree()
  },
  filters: {
    province(val) {
      let item = provinceList.find(item => item.value == val) || {}
      return item.label
    }
  },
  methods: {
    getTree() {
      getAllMobileMenuList({ type: 1 }).then((res) => {
        if (res.code == 200) {
          let arr = res.data || []
          this.treeList = arr
          arr.forEach(item => {
            let obj = JSON.parse(JSON.stringify(item))
            if (!obj.childrenList) return
            obj.childrenList = obj.childrenList.filter(item => {
              let regionCode = item.regionCode ? item.regionCode.split(',') : ''
              return ((!regionCode || regionCode.includes(this.provinceCode)) && item.isDisable == 0)
            })
            if (item.code == 'home1') {
              this.list1 = obj
            } else if (item.code == 'home2') {
              this.list2 = obj
            } else if (item.code == 'home3') {
              this.list3 = obj
            } else if (item.code == 'home4') {
              this.list4 = obj
            } else if (item.code == 'home5') {
              this.list5 = obj
            } else if (item.code == 'home6') {
              this.list6 = obj
            }
            if (this.nowMenu.id == item.id) {
              this.nowMenu = JSON.parse(JSON.stringify(item))
            }
          });
        } else {
          this.$message.error({
            message: res.msg || res.error_msg,
            duration: 1500
          });
        }
      })
    },
    menuClick(type) {
      let index = this.treeList.findIndex(item => item.code == type)
      if (index == -1) return
      this.nowMenu = this.treeList[index]
    },
    // type == 0 查看详情 type == 1添加 type == 2 编辑
    showAdd(type, row) {
      this.type = type;
      this.row = row;
      this.show = true;
    },
    setDisable(id, type, index) {
      let param = {
        id
      }
      this.$confirm(Number(type) == 1 ? '是否显示该菜单?' : '是否隐藏该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isDisable(param)
          .then((response) => {
            if (response.code == 200) {
              this.getTree();
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    deleteOne(id) {
      this.$confirm('是否删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMobileMenu({ id }).then(res => {
          if (res.code != 200) {
            this.$message.error('删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getTree();
        })
      });
    },
    datadragEnd() {
      console.log('移动后', this.nowMenu.childrenList)
    },
    onSubmit() {
      let list = this.nowMenu.childrenList.map(item => item.id)
      let form = {
        id: this.nowMenu.id,
        childrenId: String(list)
      };
      let success = res => {
        console.log(res)
        if (res.code != 200) {
          this.$message.error('保存失败')
          return
        }
        this.getTree();
        this.$message.success('保存成功')
      }
      updateMobileMenu(form).then(success)
    },
  }
};
</script>
<style scoped lang="scss">
.h5-box {
  width: 375px;
  height: 800px;
  // overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}
.product-box {
  width: 353px;
  height: 101px;
  margin: 0 auto;
  // background: #ffffff;
  // box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding-top: 14px;
  border: 1px solid #000;
  .product-samll-box {
    // height: 183px;
    width: 332px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    // flex-wrap: wrap;
    // overflow-x: auto;
    // overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .product {
    width: 66px;
    height: 89px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    &:nth-child(4n + 0) {
      margin-right: 0;
    }
    .img-box {
      position: relative;
      width: 42px;
      height: 42px;
      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .note {
        box-sizing: content-box;
        margin: 0;
        padding: 2px 5px;
        position: absolute;
        top: -5px;
        right: -20px;
        // width: 32px;
        font-size: 10px;
        color: #fff;
        text-align: center;
        overflow: hidden;
        background: #ff5353;
        border-radius: 5px 5px 5px 0;
        transform: scale(0.5);
      }
    }
    .title {
      margin: 0;
      padding: 0;
      height: 21px;
      font-size: 13px;
      color: #333333;
      white-space: nowrap; /*规定段落中的文本不进行换行 */
    }
    .active {
      width: 75px;
      margin: 0;
      padding: 0;
      font-size: 10px;
      color: #bfbfbf;
      overflow: hidden; /*超出部分隐藏*/
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
    }
  }
}
.active1 {
  height: 150px;
  width: 353px;
  margin: 10px auto;
  border: 1px solid #000;
  display: flex;
  .img {
    width: 100%;
    height: 100%;
  }
  .active-small {
    height: 100%;
    width: 332px;
    margin: 0 auto;
    display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;
    // &::-webkit-scrollbar {
    //   display: none !important;
    // }
    .img {
      width: 30%;
      height: 100%;
      margin-right: 15px;
    }
  }
}
.active3 {
  padding: 6px;
  display: flex;
  justify-content: space-between;
  &-top {
    flex: 1;
  }
  &-bottom {
    margin-left: 6px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .img {
      height: 64px;
      &:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
}
.active5 {
  height: 125px;
  flex-wrap: wrap;
  overflow: hidden;
  .img {
    width: 90px;
    height: 120px;
    margin: 10px;
  }
}
.right-box {
  width: 100%;
  height: 800px;
  background: #fff;
  padding: 60px;
  box-sizing: border-box;
  .content-text {
    margin: 10px;
    span {
      font-style: 14px;
      font-weight: 700;
      margin-right: 40px;
    }
  }
}

.coupons-box {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: inline-block;
  width: 96%;
  margin: 10px 2% 2px;
  padding: 5px 0px 0px;
  font-size: 14px;
  border-radius: 5px;
}
.coupons-img {
  background: no-repeat center;
  background-size: 100% 70px;
  font-size: 32px;
  position: relative;
  float: left;
  width: 92px;
  height: 70px;
  margin: 2px 0px 0px 6px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(248, 71, 51);
  text-align: center;
  line-height: 30px;
}
.right-info {
  position: relative;
  float: right;
  padding: 0px 5px 0px 0px;
  width: 250px;
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
}
.right-info .title {
  width: 100%;
  font-size: 16px;
  color: #000000;
  font-weight: bolder;
  line-height: 25px;
}
.right-info .tips {
  margin-top: 20px;
  width: 100%;
  font-size: 12px;
  color: #333333;
  line-height: 20px;
}
.operation-bar {
  display: flex;
  border-top: 1px solid #f1f1f1;
  width: 98.5%;
  margin: 6px 0px 0px 1.5%;
  padding: 6px 0px;
}
.operation-bar .address {
  flex: 1;
  font-size: 12px;
  padding: 4px 0px 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}
.operation-bar .rate {
  margin: 4px 4px 0px 0px;
}
.operation-bar p {
  padding: 4px 0px 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 16px;
  color: rgb(219, 33, 33);
}
.operation-bar .btn {
  font-size: 12px;
  width: 44px;
  margin-right: 4px;
}
</style>